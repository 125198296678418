import React from 'react'
import NewsCardFS from './NewsCardFS'

function Top3News() {
  return (
    <NewsCardFS />
  )
}

export default Top3News
